import React from "react";
import "antd/dist/antd.css";
import {
    Table,
    Button,
    message
} from "antd";
import { CopyOutlined,DeleteOutlined } from '@ant-design/icons';
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { getColumnCustomAttributeProps } from "../../../utils/TableHelper";
import { getBaseDomainName } from "../../../utils/UrlHelper";


class MySavedRequests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entryList: null,
            checkedList: this.props.settings,
        };
    }

  
    saveSettings = () => {
        this.props.newSettings(this.props.index, this.state.checkedList);
        this.props.closeSettings(this.props.index);
    };
    onChangeCheckbox = (list) => {
        this.setState({
            checkedList: list,
        });
    };
    copyValue = (value) => {
        const { t } = this.props;
        function fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textArea);
        }

        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(value);
            return;
        }
        navigator.clipboard.writeText(value).then(function () {
            message.success(t('app.advancedSearch.copied'));
            console.log('Async: Copying to clipboard was successful!');
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    };

    render() {
        const { t } = this.props;
        const columns = [
            {
                dataIndex: "id",
                key: "id",
                width:"10%",
                render: (text, record) => {
                    let url = getBaseDomainName()
                    return (
                 <Button title={t('app.advancedSearch.copyValueButton')} onClick={() => this.copyValue(`${url}/search/${text}`)}  type="link" ><CopyOutlined /></Button>
                   )
                },
            },
            {
                dataIndex: "name",
                key: "name",
                render: (text, record) => {
                    return (<span>
                      <a href={"/search/"+record.id} target="blank">{text}</a>  
                    </span>)
                },
            },
            {
                dataIndex: "id",
                key: "id",
                width:"5%",
                render: (text, record) => {
                    return (
                       <Button title={t('app.advancedSearch.copyValueButton')} onClick={() => this.props.deleteUserCustomization(text)}  type="link" ><DeleteOutlined style={{fontSize: '16px'}}/></Button>
                   )
                },
            },
        ]

        if (this.props.settings) {
            let col = null
            this.props.settings.forEach((el) => {
                switch (el) {
                    case "inserted":
                        col = {
                            ...getColumnCustomAttributeProps(
                                "inserted",
                                t("app.userDashboard.historyEntry.tblEntryCreated"),
                                "date",
                                t
                            ),
                            onFilter: (value, record) => record.inserted.indexOf(value) === 0
                        }
                        columns.push(col)
                        break;
                    case "updated":
                        col = {
                            ...getColumnCustomAttributeProps(
                                "updated",
                                t("app.userDashboard.historyEntry.tblEntryUpdated"),
                                "date",
                                t
                            ),
                            onFilter: (value, record) => record.inserted.indexOf(value) === 0
                        }
                        columns.push(col)
                        break;

                    default:
                        break;
                }
            })
        }
        let optionsCheckbox = [
            {
                label: t("app.userDashboard.historyEntry.tblEntryCreated"),
                value: 'inserted',
            },
            {
                label: t("app.userDashboard.historyEntry.tblEntryUpdated"),
                value: 'updated',
            }
        ]
        // let drawer =
        //     <Drawer
        //         height={'60%'}
        //         placement="top"
        //         closable={false}
        //         onClose={() => this.props.closeSettings(this.props.index)}
        //         size='small'
        //         getContainer={false}
        //         visible={this.props.showSettings}
        //         footer={
        //             <Space>
        //                 <Button onClick={() => this.props.closeSettings(this.props.index)}>{t("app.userDashboard.historyEntry.btnCancel")}</Button>
        //                 <Button type="primary" onClick={this.saveSettings}>
        //                     {t("app.userDashboard.historyEntry.btnOk")}
        //                 </Button>
        //             </Space>
        //         }
        //         footerStyle={{ textAlign: 'right' }}
        //         style={{
        //             position: 'absolute',
        //         }}><Space>
        //         <Text>{t("app.userDashboard.historyEntry.labelForSetup")}</Text>
        //         <CheckboxGroup options={optionsCheckbox} value={this.state.checkedList} onChange={this.onChangeCheckbox} />
        //         </Space>
        //     </Drawer>

        let table =  <Table
        showHeader={false}
        pagination={true}
        columns={columns}
        dataSource={this.props.requestList}
        rowKey="id"
        size="small"
        scroll={{
            x: true,
        }}
    />
        

        return (
            <div className="site-drawer-render-in-current-wrapper">
                {/* {drawer} */}
                {table}
            </div>

        )
    }
}


const MySavedRequestsRouter = withRouter(MySavedRequests);

export default withTranslation()(MySavedRequestsRouter);
